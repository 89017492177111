<template>
  <div class="t15">
    <div class="form-section-static first-section ">
      <div class="crs_content ">
        <navbar>
          <template slot="collapse">
            <navbar-nav>
              <li v-bind:class="{ active: !$route.params.mode || $route.params.mode ==='huttoheart' }">
                <router-link to="/crs">About hut to heart</router-link>
              </li>
              <dropdown tag="li">
                <a v-bind:class="{ active: $route.params.mode ==='sayangimu' }" class="dropdown-toggle" role="button">Sayangimu
                  <span class="caret"></span></a>
                <template slot="dropdown">
                  <li>
                    <router-link to="/crs/sayangimu">About sayangimu</router-link>
                  </li>
                  <li>
                    <router-link to="/crs/sayangimu/howhelp">How you can help</router-link>
                  </li>
                </template>
              </dropdown>
            </navbar-nav>
          </template>
        </navbar>
      </div>
    </div>
    <div class="row  xs-fill-white pt-20 lg:px-60" style="margin: auto; max-width: 990px">
      <div class="selection-time text-center pb20  "
           style="position: relative;overflow: hidden; margin: auto;max-width: 960px">
        <div v-if="!$route.params.mode || $route.params.mode ==='huttoheart' ">
          <h2 class="cl-red t22 pb-10">ABOUT HUT TO HEART</h2>
          <div class="text-left t14" style="margin-bottom: 0px">
            <p>Putting smiles on our customers' faces over an oven-fresh pizza is what we live for.<br>It doesn't stop
              here. We believe in bringing smiles not just to our customers, but to the community and fellow Malaysians
              in need.</p>
            <p>And to do that, we're fighting against cancer - the 3rd leading cause of death in Malaysia - because the
              good news is 30% to 50% of cancer diseases can be prevented through early cancer detection.</p>
            <p>To achieve this, we bring you "Hut to Heart", our community outreach CSR program.</p>
          </div>
          <div style="margin-bottom: 0px">
            <img src="https://static.phdvasia.com/mx/images/H2H-Manifesto1.png">
          </div>
          <div style="position: relative; margin-bottom: 50px">
            <img src="https://static.phdvasia.com/mx/images/H2H-Manifesto2.png">
            <router-link class='btn-learnmore' style="position: absolute; left:55%; top:82%;width:28.4%"
                         to="/crs/sayangimu"><img style="width: 100%"
                                                  src="https://static.phdvasia.com/mx/images/static/csr/Button.png">
            </router-link>
          </div>
        </div>
        <div v-else-if=" !$route.params.sub || $route.params.sub ==='sayangimu'">
          <h2 class="cl-red t22 pb-10">ABOUT SAYANGIMU</h2>
          <div class="text-left t14" style="margin-bottom: 0px">
            <div style="float:left; width:70%">
              <p>Over 5000 Malaysian families are affected by breast cancer every year.</p>
              <p>However, many women have little awareness on cancer and up to 70% women in rural communities do not
                know how to perform a breast self-examination.</p>
              <p>The good news is that about 30% to 50% of cancers can be prevented with early detection.</p>
              <p>Powered by our mandate for Hut to Heart, we recognise this threat, and wish to help women rise against
                breast cancer.</p>
            </div>
            <div style="float:right; width:25%">
              <img style="width: 100%" src="https://static.phdvasia.com/mx/images/static/csr/bow.png"/>
            </div>
            <div style="clear:both"></div>
          </div>
          <div style="position: relative; margin-bottom: 50px">
            <img src="https://static.phdvasia.com/mx/images/static/csr/About-SayangiMu.png">
            <router-link class='btn-learnmore' to="/crs/sayangimu/howhelp"
                         style="position: absolute; left:47.8%; bottom:3%;width:28%"><img style="width: 100%"
                                                                                          src="https://static.phdvasia.com/mx/images/static/csr/learn-more.png">
            </router-link>
          </div>
        </div>
        <div v-else>
          <h2 class="cl-red t22 pb-10">HOW YOU CAN HELP</h2>
          <div class="text-left t14" style="margin-bottom: 0px">
            <p>Over 5000 Malaysian families are affected by breast cancer every year.</p>
            <p>While early detection saves lives, up to 70% of women in rural communities do not know how to perform a
              breast self-examination.</p>
            <p>Join the cause by donating to provide women in rural communities with education and access to medical
              infrastructure that they desperately need.</p>
          </div>
          <div style="position: relative; margin-bottom: 50px">
            <img src="https://static.phdvasia.com/mx/images/static/csr/How-you-can-help20180604.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {}
}
</script>
